// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'mapbox-gl/dist/mapbox-gl.css';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import '@fortawesome/fontawesome-free/css/all.css';

@import 'animate.css/animate.min.css';

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'animate.css/animate.min.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
$mgmt-theme-primary: (
  50: #b3c6ff,
  100: #99b3ff,
  200: #809fff,
  300: #668cff,
  400: #4d79ff,
  500: #36f,
  600: #1953ff,
  700: #0040ff,
  800: #0039e5,
  900: #0033cc,
  A100: #ccd9ff,
  A200: #e5ecff,
  A400: #ffffff,
  A700: #002db3,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);
$mgmt-theme-accent: (
  50: #997300,
  100: #b38600,
  200: #cc9900,
  300: #e5ac00,
  400: #ffbf00,
  500: #ffc619,
  600: #ffd24d,
  700: #ffd966,
  800: #ffdf80,
  900: #ffe699,
  A100: #ffd24d,
  A200: #ffcc33,
  A400: #ffc619,
  A700: #ffecb3,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);
$mgmt-theme-warn: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ms-contactless-management-front-end-primary: mat-palette($mgmt-theme-primary);
$ms-contactless-management-front-end-accent: mat-palette($mgmt-theme-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ms-contactless-management-front-end-warn: mat-palette($mgmt-theme-warn);

// Create the theme object (a Sass map containing all of the palettes).
$ms-contactless-management-front-end-theme: mat-light-theme(
  $ms-contactless-management-front-end-primary,
  $ms-contactless-management-front-end-accent,
  $ms-contactless-management-front-end-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ms-contactless-management-front-end-theme);

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// Inter font family
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  //@include nb-auth-global();

  @include ngx-layout();

  @include nb-overrides();
}

a {
  cursor: pointer;
}

.required-field {
  display: flex;
  margin-left: -9px;

  span {
    color: red;
    display: block;
    margin-top: -13px;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Open Sans, 'Helvetica Neue', sans-serif;
}
.nb-theme-default .cdk-overlay-container {
  z-index: 10400;
}
.nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0;
}

.application-page {
  padding: 48px;
  &.full-width {
    padding-left: 0;
    padding-right: 0;
  }
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.toggle-label {
  margin-bottom: 0;
}

mat-form-field.mat-form-field {
  width: 100%;
}
button:focus {
  outline: none;
}

.nb-theme-default nb-layout-header {
  background-color: #2e3138;
  color: #ffffff;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}
.nb-theme-default nb-context-menu {
  background-color: #2e3138;
  border-color: transparent;
  color: #ffffff;
  border-style: solid;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  min-width: 10rem;
  max-width: 15rem;
}
.nb-theme-default nb-user .user-name {
  color: #ffffff;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
}

.nb-theme-default nb-menu .menu-item a {
  color: white !important;
  transition: all 0.3s;
  &:hover {
    color: white;
    background: lighten(#2e3138, 10);
  }
  nb-icon {
    color: white !important;
  }
}
.nb-theme-default nb-menu .menu-item {
  border-bottom: 1px solid lighten(#2e3138, 30);
}
.nb-theme-default nb-layout .layout .layout-container .content nb-layout-footer nav {
  background-color: #2e3138;
  border-top: 1px solid #edf1f7;
  color: white;
  font-family: Open Sans, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 1.25rem;
}
nav.fixed {
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 12px;
  padding-bottom: 12px;
  .header-container {
    padding-right: 0;
    padding-left: 0;
  }
}

.nb-theme-default nb-layout .layout .layout-container .content nb-layout-footer nav {
  padding: 12px;
}
.nb-theme-default nb-checkbox {
  box-shadow: none !important;
  outline: none !important;
  * {
    box-shadow: none !important;
    outline: none !important;
  }
  ::ng-deep * {
    box-shadow: none !important;
    outline: none !important;
  }
}
.user-container {
  flex-direction: row-reverse;
  .user-name {
    margin-left: 0;
    margin-right: 0.5rem;
    font-size: 16px;
    font-weight: 700;
  }
}

mat-icon,
nb-icon {
  cursor: default;
}
.mat-bottom-sheet-container {
  padding: 0 !important;
}
.nb-theme-default nb-layout .layout .layout-container .content nb-layout-footer nav {
  border-top: none;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #d5ddeb;
  cursor: default;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f2f4;
}

.wizard-card {
  padding: 20px 22px;
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  + .wizard-card {
    margin-top: 20px;
  }
}
html {
  scroll-behavior: smooth;
}

.nb-theme-default nb-layout-header.fixed ~ .layout-container {
  padding-top: 3.75rem;
  min-height: 100vh;
}

.nb-theme-default nb-user.size-medium .user-picture {
  height: 2rem;
  width: 2rem;
}
.nb-theme-default nb-layout-header nav {
  color: #222b45;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  height: 2.7rem;
  padding-right: 23px;
  padding-left: 23px;
  padding-top: 0;
  padding-bottom: 0;
}
.nb-theme-default nb-layout-header.fixed ~ .layout-container {
  padding-top: 2.7rem;
  min-height: 100vh;
}

.fb_dialog_content {
  iframe {
    right: 0 !important;
    bottom: 32px !important;
  }
}

a {
  text-decoration: none !important;
}

nb-accordion-item-header.expansion-indicator {
  right: 0 !important;
  .mce-edit-focus {
    outline: none !important;
  }
}

.mce-content-body {
  outline: none !important;
  p {
    margin: 0 !important;
  }
}

#hubspot-messages-iframe-container.widget-align-right {
  z-index: 99 !important;
  bottom: 25px !important;
}
